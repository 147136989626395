import { graphql, useStaticQuery } from 'gatsby'

export default function useAllCourses() {
  const data = useStaticQuery(graphql`
    query GetAllPublishedCourses {
      allCoursesJson(filter: { published: { eq: true } }) {
        edges {
          node {
            id
            title
            description
            categories
            image {
              src {
                childImageSharp {
                  fixed(width: 200) {
                    src
                  }
                }
              }
              alt
            }
            price {
              max
              min
            }
          }
        }
      }
    }
  `)

  return data.allCoursesJson.edges.map(edge => edge.node)
}
