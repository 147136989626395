import { Link } from 'gatsby'
import React from 'react'
import { bs } from '../shevy'
import Flex from './Flex'
import ShiftBy from './ShiftBy'
import Spacer from './Spacer'

export default function CourseItem({ course }) {
  const { id, description, categories, title, image } = course
  const logoSrc = image?.src?.childImageSharp?.fixed?.src

  return (
    <li
      css={{
        border: `4px solid var(--colors-accent)`,
        borderRadius: 8,
        color: 'var(--colors-text)',
        display: 'block',
        transition: 'background-color .3s ease',

        '&:hover': {
          backgroundColor: 'var(--colors-offset)',
        },
      }}
    >
      <Link
        css={{
          color: 'inherit',
          display: 'block',
          padding: bs(),

          '&:hover': { color: 'inherit' },
        }}
        to={`/courses/${id}`}
      >
        <Flex gap={1} justify="space-between">
          <div>
            <h4 css={{ fontWeight: 'bold', marginBottom: bs(0.25) }}>
              {title}
            </h4>
            {description && (
              <div dangerouslySetInnerHTML={{ __html: description }} />
            )}
          </div>
          {logoSrc && (
            <div>
              <img alt={image.alt ?? ''} src={logoSrc} />
            </div>
          )}
        </Flex>
        <Spacer top={0.5}>
          <Flex justify="space-between" align="center" gap={bs()}>
            <div css={{ fontFamily: 'var(--fonts-catamaran)' }}>
              Categories: {categories.join(', ')}
            </div>
            <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'var(--colors-accent)',
                  borderRadius: 4,
                  color: 'white',
                  fontFamily: 'var(--fonts-catamaran)',
                  padding: `${bs(0.25)} ${bs(0.5)}`,
                  textAlign: 'center',
                }}
              >
                <ShiftBy y={-2}>Learn More</ShiftBy>
              </div>
            </div>
          </Flex>
        </Spacer>
      </Link>
    </li>
  )
}
